.login-layout {
  height: 100vh;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    background: white;
    border-bottom: 1px solid #e5e5e5;

    .logo {
      margin-bottom: 0.5rem;
    }
  }

  .login-form {
    margin-bottom: auto;

    .form {
      width: 100%;
      max-width: 25rem;
      padding: 2rem;
      margin: 5rem auto 0;
      background: white;
      border: 1px solid #e5e5e5;
      border-radius: 1rem;

      .title {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      .login-form-button {
        background-color: #F7CC5B;
        width: 100%;
        margin-top: 1rem;
      }

      .login-form-button:hover {
        background-color: #DEA100;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}
